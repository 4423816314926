<template>
  <v-app id="inspire" style="background: #fafafa">
    <v-container fluid class="mx-auto" style="max-width: 1200px">
      <v-card color="basil">
        <v-card-title class="text-center justify-center py-6">
          <h1
            class="font-weight-bold text-h5 basil--text"
            style="word-break: break-word"
          >
            AgeAI Analytics
          </h1>
        </v-card-title>

        <v-tabs
          v-model="activeTab"
          background-color="transparent"
          color="#18739d"
          grow
        >
          <v-tab class="text-capitalize text-h6">Yesterday</v-tab>
          <v-tab class="text-capitalize text-h6">Last 7 Days</v-tab>
        </v-tabs>

        <v-tabs-items v-model="activeTab">
          <v-tab-item>
            <v-card color="basil" flat>
              <v-card-text>
                <v-row align="center" justify="center">
                  <v-col cols="12" sm="4" md="3">
                    <div
                      class="d-flex align-center justify-center"
                      style="min-height: 150px"
                    >
                      <estimation-card
                        :metricTitle="'Total Faces Scanned'"
                        :metricValue="yesterdayData.total_scanned_faces"
                        :loading="loading"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4" md="3">
                    <div
                      class="d-flex align-center justify-center"
                      style="min-height: 150px"
                    >
                      <estimation-card
                        metricTitle="Adult Pass Rate"
                        :metricValue="yesterdayData.adult_pass_rate"
                        :loading="loading"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4" md="3">
                    <div
                      class="d-flex align-center justify-center"
                      style="min-height: 150px"
                    >
                      <estimation-card
                        metricTitle="Refusal Count"
                        :metricValue="yesterdayData.refusal_count"
                        :loading="loading"
                      />
                    </div>
                  </v-col>
                </v-row>

                <v-row
                  v-if="hasYesterdayRefusalData"
                  class="mt-4"
                  justify="center"
                >
                  <v-col cols="12" sm="8" md="6" class="text-center">
                    <apexchart
                      type="pie"
                      height="350"
                      :options="pieChartOptions"
                      :series="yesterdayPieSeries"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card color="basil" flat>
              <v-card-text>
                <v-row align="center" justify="center">
                  <v-col cols="12" sm="4" md="3">
                    <div
                      class="d-flex align-center justify-center"
                      style="min-height: 150px"
                    >
                      <estimation-card
                        :metricTitle="'Total Faces Scanned'"
                        :metricValue="last7DaysData.total_scanned_faces"
                        :loading="loading"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4" md="3">
                    <div
                      class="d-flex align-center justify-center"
                      style="min-height: 150px"
                    >
                      <estimation-card
                        metricTitle="Adult Pass Rate"
                        :metricValue="last7DaysData.adult_pass_rate"
                        :loading="loading"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4" md="3">
                    <div
                      class="d-flex align-center justify-center"
                      style="min-height: 150px"
                    >
                      <estimation-card
                        metricTitle="Refusal Count"
                        :metricValue="last7DaysData.refusal_count"
                        :loading="loading"
                      />
                    </div>
                  </v-col>
                </v-row>

                <v-row
                  v-if="hasLast7DaysRefusalData"
                  class="mt-4"
                  justify="center"
                >
                  <v-col cols="12" sm="8" md="6" class="text-center">
                    <apexchart
                      type="pie"
                      height="350"
                      :options="pieChartOptions"
                      :series="last7DaysPieSeries"
                    />
                  </v-col>
                </v-row>

                <v-row class="mt-4" justify="center">
                  <v-col cols="12" sm="10" md="10">
                    <apexchart
                      type="bar"
                      height="350"
                      :options="stackedChartOptions"
                      :series="stackedSeries"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
import EstimationCard from "@/components/cards/EstimationCard.vue";

export default {
  name: "AnalyticsView",
  components: {
    apexchart: VueApexCharts,
    EstimationCard,
  },
  data() {
    return {
      activeTab: 0,
      token: "",
      loading: false,
      yesterdayData: {
        total_scanned_faces: 0,
        adult_pass_rate: "0%",
        refusal_count: 0,
      },
      last7DaysData: {
        total_scanned_faces: 0,
        adult_pass_rate: "0%",
        refusal_count: 0,
      },
      stackedChartCategories: [],
      yesterdayPieSeries: [0, 0, 0, 0, 0],
      last7DaysPieSeries: [0, 0, 0, 0, 0],
      stackedSeries: [
        { name: "Failed Faces", data: [] },
        { name: "Passed Faces", data: [] },
      ],
    };
  },
  computed: {
    hasYesterdayRefusalData() {
      return this.yesterdayPieSeries.some((value) => value > 0);
    },
    hasLast7DaysRefusalData() {
      return this.last7DaysPieSeries.some((value) => value > 0);
    },
    pieChartOptions() {
      return {
        chart: { type: "pie" },
        title: {
          text: "Refusal/Acceptance Reason",
          align: "center",
          style: { fontSize: "16px", fontWeight: "bold" },
          margin: 10,
        },
        labels: [
          "ID Check Passed",
          "Visually Passed",
          "ID Check Failed",
          "Visually Failed",
          "ID not provided",
        ],
        legend: {
          position: "right",
          offsetY: !this.$vuetify.breakpoint.xs ? 60 : 0,
        },
        colors: ["#386641", "#a7c957", "#fed9b7", "#f07167", "#bc4749"],
        responsive: [
          {
            breakpoint: 600,
            options: {
              legend: { position: "bottom" },
            },
          },
        ],
      };
    },
    stackedChartOptions() {
      return {
        chart: {
          type: "bar",
          stacked: true,
          stackType: "100%",
          toolbar: { show: true },
        },
        title: {
          text: "Trend for last 7 days",
          align: "center",
          style: { fontSize: "16px", fontWeight: "bold" },
          margin: 10,
        },
        plotOptions: {
          bar: { horizontal: false },
        },
        dataLabels: {
          enabled: true,
          formatter: (val, opts) => {
            const seriesIndex = opts.seriesIndex;
            const dataPointIndex = opts.dataPointIndex;
            return this.stackedSeries[seriesIndex].data[dataPointIndex];
          },
          style: { colors: ["#fff"] },
        },
        xaxis: {
          categories: this.stackedChartCategories,
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return val + "%";
            },
          },
          min: 0,
          max: 100,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
        fill: { opacity: 1 },
        colors: ["#FF4560", "#00E396"],
        tooltip: {
          y: {
            formatter: (val, { dataPointIndex, w }) => {
              const total = w.globals.series.reduce(
                (acc, curr) => acc + curr[dataPointIndex],
                0
              );
              const percentage = total ? ((val / total) * 100).toFixed(1) : 0;
              return `${percentage}%`;
            },
          },
        },
      };
    },
  },
  mounted() {
    this.token = this.$route.query.token;
    const apiUrl =
      "https://frhs2a9faa.execute-api.eu-west-1.amazonaws.com/default/ageai_inapp_metrics_api";
    const config = {
      headers: {
        Authorization: `Bearer ${this.token}`,
        "Content-Type": "application/json",
      },
    };
    this.loading = true;

    axios
      .get(apiUrl, config)
      .then((response) => {
        const data = response.data.result;
        if (data && data.length) {
          const sorted = data.sort(
            (a, b) =>
              new Date(a.start_date).getTime() -
              new Date(b.start_date).getTime()
          );
          const today = new Date();
          today.setHours(12, 0, 0, 0);
          const yesterday = new Date(today);
          yesterday.setDate(yesterday.getDate() - 1);
          const yEntry = sorted.find((item) => {
            const itemDate = new Date(item.start_date);
            itemDate.setHours(12, 0, 0, 0);
            return itemDate.getTime() === yesterday.getTime();
          });
          if (yEntry) {
            this.yesterdayData.total_scanned_faces = yEntry.total_scanned_faces;
            const yFailed = yEntry.total_failed_faces;
            const yScanned = yEntry.total_scanned_faces;
            this.yesterdayData.adult_pass_rate = yScanned
              ? `${(((yScanned - yFailed) / yScanned) * 100).toFixed(1)}%`
              : "0%";
            this.yesterdayData.refusal_count = yFailed;
            this.yesterdayPieSeries = [
              yEntry.total_id_check_passed || 0,
              yEntry.total_visually_passed || 0,
              yEntry.total_id_check_failed || 0,
              yEntry.total_visually_failed || 0,
              yEntry.total_id_not_provided || 0,
            ];
          } else {
            this.yesterdayData = {
              total_scanned_faces: 0,
              adult_pass_rate: "0%",
              refusal_count: 0,
            };
            this.yesterdayPieSeries = [0, 0, 0, 0, 0];
          }
          const last7DaysArray = [];
          for (let i = 7; i >= 1; i--) {
            const d = new Date(today);
            d.setDate(d.getDate() - i);
            last7DaysArray.push(d);
          }
          const final7Data = last7DaysArray.map((day) => {
            const match = sorted.find((item) => {
              const itemDate = new Date(item.start_date);
              itemDate.setHours(12, 0, 0, 0);
              return itemDate.getTime() === day.getTime();
            });
            if (match) {
              return match;
            }
            return {
              start_date: day.toISOString().split("T")[0],
              total_scanned_faces: 0,
              total_failed_faces: 0,
              total_visually_failed: 0,
              total_id_not_provided: 0,
              total_id_check_passed: 0,
              total_id_check_failed: 0,
              total_visually_passed: 0,
              total_passed_faces: 0,
            };
          });
          const totalScanned7 = final7Data.reduce(
            (acc, cur) => acc + cur.total_scanned_faces,
            0
          );
          const totalFailed7 = final7Data.reduce(
            (acc, cur) => acc + cur.total_failed_faces,
            0
          );
          const totalPassed7 = totalScanned7 - totalFailed7;
          this.last7DaysData.total_scanned_faces = totalScanned7;
          this.last7DaysData.adult_pass_rate = totalScanned7
            ? `${((totalPassed7 / totalScanned7) * 100).toFixed(1)}%`
            : "0%";
          this.last7DaysData.refusal_count = totalFailed7;
          const totalIdCheckPassed7 = final7Data.reduce(
            (acc, cur) => acc + cur.total_id_check_passed,
            0
          );
          const totalVisuallyPassed7 = final7Data.reduce(
            (acc, cur) => acc + cur.total_visually_passed,
            0
          );
          const totalIdCheckFailed7 = final7Data.reduce(
            (acc, cur) => acc + cur.total_id_check_failed,
            0
          );
          const totalVisuallyFailed7 = final7Data.reduce(
            (acc, cur) => acc + cur.total_visually_failed,
            0
          );
          const totalIdNotProvided7 = final7Data.reduce(
            (acc, cur) => acc + cur.total_id_not_provided,
            0
          );
          this.last7DaysPieSeries = [
            totalIdCheckPassed7,
            totalVisuallyPassed7,
            totalIdCheckFailed7,
            totalVisuallyFailed7,
            totalIdNotProvided7,
          ];
          this.stackedChartCategories = final7Data.map(
            (item) => item.start_date
          );
          this.stackedSeries = [
            {
              name: "Failed Faces",
              data: final7Data.map((item) => item.total_failed_faces),
            },
            {
              name: "Passed Faces",
              data: final7Data.map(
                (item) =>
                  item.total_passed_faces ??
                  item.total_scanned_faces - item.total_failed_faces
              ),
            },
          ];
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style scoped>
.basil--text {
  color: #18739d;
}
</style>
