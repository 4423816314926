<template>
  <div>
    <PDFViewer :pdfUrl="pdfUrl" />
  </div>
</template>

<script>
import PDFViewer from "@/components/PDFViewer.vue";

export default {
  name: "DocumentView",
  components: {
    PDFViewer,
  },
  computed: {
    pdfUrl() {
      return `https://ageai.app/static/docs/${this.$route.params.lang}/${this.$route.params.documentName}.pdf`;
    },
  },
};
</script>